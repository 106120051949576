import {
  AbortableFetch,
  AbortableFetchReturnProps,
  apiFetch,
  defaultOptions,
  Options,
} from '@telekomconsalting/dex-guru-fetch'

import { getSessionHeader } from './sessionManager'

/**
 * @deprecated Delete when apiFetch is ready to sunset
 */
const sessionAwareApiFetch = <T>(
  url: string,
  path: string,
  options: Options<T> = defaultOptions
): Promise<T | undefined> =>
  apiFetch(url, path, {
    ...options,
    init: {
      ...(options?.init || {}),
      headers: {
        ...(options?.init?.headers || {}),
        ...getSessionHeader(),
      },
    },
  })

/**
 * @deprecated Delete when apiFetch is ready to sunset
 */
const sessionAwareAbortableFetch = <T>(
  url: string,
  path: string,
  options: Options<T> = defaultOptions,
  controllerKey: string
): Promise<AbortableFetchReturnProps<T> | undefined> =>
  AbortableFetch(
    url,
    path,
    {
      ...options,
      init: {
        ...(options?.init || {}),
        headers: {
          ...(options?.init?.headers || {}),
          ...getSessionHeader(),
        },
      },
    },
    controllerKey
  )

export { sessionAwareAbortableFetch, sessionAwareApiFetch }
