export interface FetchOptions<T> {
  expectJson?: boolean
  expectText?: boolean
  mapErrorTo?: {
    shouldMap: boolean
    targetValue?: T
  }
  onError?: (response: Response) => void
  init?: {
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
    body?: string
    cache?: 'default' | 'force-cache' | 'no-cache' | 'no-store' | 'only-if-cached' | 'reload'
    headers?: {
      [key: string]: string
    }
    signal?: AbortSignal | null | undefined
  }
}

export type ErrorDetails = {
  detail?: Record<string, unknown> | string
}
export type ApiError = {
  message: string
  errorData?: ErrorDetails
  status?: number
}

export type ProxyApiFetchReturn<T> = {
  responseData?: T
  error?: ApiError
}

export enum ResponseErrorMessages {
  signatureVerificationFailed = 'Signature verification failed',
}
