import { v4 as uuid } from 'uuid'

const generateSessionId = (): string => uuid().replace(/-/g, '')

const sessionId = generateSessionId()

export enum HeaderKeys {
  xSessionId = 'X-Session-Id',
}

export const getSessionHeader = (): { [HeaderKeys.xSessionId]: string } => {
  return { [HeaderKeys.xSessionId]: sessionId }
}
