class ResponseError extends Error {
  status?: number
  errorData?: unknown
  constructor({
    status,
    message,
    errorData,
  }: {
    status?: number
    message: string
    errorData?: unknown
  }) {
    super(message)
    this.name = 'ResponseError'
    this.status = status
    this.errorData = errorData
  }
}

export default ResponseError
