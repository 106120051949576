import ResponseError from './ResponseError'

const getMessageByError = (error: Error | ResponseError | unknown): string => {
  let message = 'other_error'

  if (error instanceof ResponseError) {
    message = `[response error] ${error.status} ${error.message}`
  }

  return message
}

export default getMessageByError
