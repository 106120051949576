import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { timestampFromUnixToWeb } from '../utils'

// init UTC plugin https://day.js.org/docs/en/plugin/utc
dayjs.extend(utc)

export type UTCDate = InstanceType<typeof Date>['getUTCDate'] | number

export type Milliseconds = number
export type Seconds = number // 1000 Milliseconds
export type UTCDateInMs = Milliseconds // 1653250924587 - Unix Timestamp (milliseconds),  (13 digits, since the Unix Epoch Jan 1 1970 12AM UTC)
export type UTCDateInSec = number // 1653250924 - Unix Timestamp (seconds), 10 digits, seconds since the Unix Epoch

export const timezoneOffset: Milliseconds = new Date().getTimezoneOffset() * 60 * 1000

// now(UTC) - 24h in ms
export const getSubtractDayFromNowUTC = (): UTCDateInMs =>
  dayjs().subtract(1, 'day').utc().valueOf()

// now(UTC) end of day in ms
export const nowUTC = (): UTCDateInMs => dayjs().utc().valueOf()

// now(UTC) - 30d, start of day
export const startOfLast30DaysUTC = (): UTCDateInMs =>
  dayjs().utc().subtract(30, 'day').startOf('D').valueOf()

export const startOfYesterdayUTC = (): UTCDateInMs =>
  dayjs(getSubtractDayFromNowUTC()).utc().startOf('D').valueOf()

export const endOfYesterdayUTC = (): UTCDateInMs =>
  dayjs(getSubtractDayFromNowUTC()).utc().endOf('D').valueOf()

export const startOfMonthUTC = (): UTCDateInMs => dayjs().utc().startOf('M').valueOf()

export const UTCSecToLocalMs = (date?: Seconds): UTCDateInMs | undefined =>
  date ? timestampFromUnixToWeb(date) + timezoneOffset : date

export const LocalToUTC = (date: UTCDateInMs): UTCDateInSec => dayjs(date).utc().unix()

export const startOfDayUTC = (date: Date): UTCDateInMs => dayjs(date).startOf('D').utc().valueOf()

export const endOfDayUTC = (date: Date): UTCDateInMs => dayjs(date).endOf('D').utc().valueOf()

export const LocalToUTCStartOfDay = (date: Date): UTCDateInMs | undefined =>
  date ? Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0) : undefined

export const LocalToUTCEndOfDay = (date: Date): UTCDateInMs | undefined =>
  date ? Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 0) : undefined

export const getDateFromTimestamp = (
  date: Seconds,
  options: Intl.DateTimeFormatOptions
): string => {
  if (!date) {
    return '-'
  }

  const newDate = new Date(date)

  return newDate.toLocaleString('en-US', { timeZone: 'UTC', ...options })
}
