/*
@returns {number} percentage of meter bar in range [-100, 100]
where negative is red bar and positive is green bar
*/
export const getBarValue = (value: number): number => {
  if (value === null || undefined) {
    return 0
  }
  return value * 2 - 100
}
