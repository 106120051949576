import { apiFetch } from '@telekomconsalting/dex-guru-fetch'
import { auth } from '@telekomconsalting/dex-guru-internal-sdk'

import getMessageByError from '../errors/getMessageByError'
import ResponseError from '../errors/ResponseError'
import { ErrorDetails, FetchOptions, ProxyApiFetchReturn, ResponseErrorMessages } from './types'

const proxyApiFetch = async <T>(
  url: string,
  path: string,
  options?: FetchOptions<T>
): Promise<ProxyApiFetchReturn<T>> => {
  try {
    const response = await apiFetch<T>(url, path, {
      ...options,
      onError: async (response) => {
        const errorData = await response.json()
        throw new ResponseError({
          status: response.status,
          message: response.statusText,
          errorData,
        })
        // if error happened above it go to `section ***`
      },
    })
    return { responseData: response }
  } catch (error) {
    // section ***

    if (error instanceof ResponseError) {
      const errorDetail = (error.errorData as ErrorDetails).detail
      if (
        error.status === 422 &&
        errorDetail === ResponseErrorMessages.signatureVerificationFailed
      ) {
        auth.removeAccessToken()
      }

      return {
        error: {
          message: getMessageByError(error),
          status: error.status,
          errorData: error.errorData as ErrorDetails,
        },
      }
    }
    return {
      error: {
        message: getMessageByError(error),
      },
    }
  }
}

export default proxyApiFetch
