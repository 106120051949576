import { getHoursUTCBeforeMidnight } from '../utils'

export type Periods = { [key: string]: number }
export type Intervals = { [key: string]: string }
export interface GetChartTimestampProps {
  period?: number
  periodString?: keyof Periods
}
export interface GetChartTimestamp {
  beginTimestamp: number
  endTimestamp: number
  interval: string
}

export const periods = {
  day: 1,
  week: 7,
  month: 30,
} as Periods

export const intervals = {
  day: 'hour',
  week: 'day',
  month: 'day',
  all_time: 'day',
} as Intervals

export const getChartTimestamps = ({
  period = 1,
  periodString,
}: GetChartTimestampProps): GetChartTimestamp => {
  const endTimestamp = getHoursUTCBeforeMidnight()

  let beginTimestamp
  let _period = period

  if (periodString && periodString === 'all_time') {
    beginTimestamp = 1588723228 // min start date allowed by the API
    return { beginTimestamp, endTimestamp, interval: intervals.all_time }
  }

  let _interval = intervals.hour
  if (periodString) {
    _period = periods[periodString]
    _interval = intervals[periodString]
  }

  beginTimestamp = Math.round(endTimestamp - _period * 3600 * 24 + 1)

  return { beginTimestamp, endTimestamp, interval: _interval }
}
