import proxyApiFetch from './proxyApiFetch'
import { getSessionHeader } from './sessionManager'
import { FetchOptions, ProxyApiFetchReturn } from './types'

const sessionAwareProxyApiFetch = <T>(
  url: string,
  path: string,
  options: FetchOptions<T> = {}
): Promise<ProxyApiFetchReturn<T>> =>
  proxyApiFetch(url, path, {
    ...options,
    init: {
      ...(options?.init || {}),
      headers: {
        ...(options?.init?.headers || {}),
        ...getSessionHeader(),
      },
    },
  })

export default sessionAwareProxyApiFetch
